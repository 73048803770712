import { Links, Scripts, LiveReload, AdminPanel, LosseLink, useRouteError, isRouteErrorResponse } from '@ubo/losse-sjedel'

export default function PageNotFound() {
  const error = useRouteError()

  return (
    <html lang="nl">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <div className="bg-site-grey min-h-screen-dynamic px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 relative">
          <img
            src="/images/404.jpg"
            alt="404"
            className="max-w-[2000px] max-md:hidden mx-auto absolute left-0 right-0 top-0 z-10 h-full w-full object-cover"
          />
          <img
            src="/images/404-mobiel.jpg"
            alt="404"
            className="max-w-[2000px] md:hidden mx-auto absolute left-0 right-0 top-0 z-10 h-full w-full object-cover"
          />
          <div className="max-w-max mx-auto relative z-20">
            <main className="sm:flex">{!isRouteErrorResponse(error) ? <Help /> : <NotFound />}</main>
          </div>
        </div>
        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}

function Help() {
  const error = useRouteError()
  let errorMessage = 'Unknown error'

  if (error instanceof Error) {
    errorMessage = error.stack
  }

  return (
    <>
      <p className="text-4xl font-extrabold text-site-accent sm:text-5xl">500</p>
      <div className="sm:ml-6">
        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 className="text-4xl text-white font-extrabold tracking-tight sm:text-5xl">Serverfout</h1>
          <p className="mt-1 text-lg text-white">
            Er is iets misgegaan op de server. Neem{' '}
            <a className="underline" href="/contact">
              contact
            </a>{' '}
            met ons op zodat we het kunnen oplossen.
          </p>

          <pre className="mt-1 text-red-500 p-2 text-sm">{errorMessage}</pre>
        </div>
        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
          <LosseLink to="/" className="btn">
            Ga naar home
          </LosseLink>
          <LosseLink to="/contact" className="btn--white">
            Contact
          </LosseLink>
        </div>
      </div>
    </>
  )
}

function NotFound() {
  return (
    <div className="rounded-[7px] p-6 sm:p-10 flex sm:flex-row flex-col">
      <p className="text-6xl max-sm:text-center font-extrabold text-site-accent sm:text-5xl">404</p>
      <div className="sm:ml-6 max-sm:mt-10">
        <div className="sm:border-l max-w-[500px] sm:border-gray-200 sm:pl-6">
          <h1 className="text-4xl text-white font-extrabold tracking-tight sm:text-5xl">Oeps, dit klopt niet helemaal...</h1>
          <p className="mt-1 text-lg text-white">
            Je bent naar een pagina geleid die niet bestaat. We helpen je graag de weg weer terug te vinden
          </p>
        </div>
        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
          <LosseLink to="/" className="btn">
            Ga naar home
          </LosseLink>
          <LosseLink to="/contact" className="btn--white">
            Contact
          </LosseLink>
        </div>
      </div>
    </div>
  )
}
